import { TA_BOOTSTRAP } from '@tripactions/core/util/ta-bootstrap.util';
import { preBootstrap } from '@tripactions/core/util/pre-bootstrap.util';

(async function main() {
  // Bootstrap application
  try {
    await TA_BOOTSTRAP.setup();
    await preBootstrap();
    import(/* webpackPreload: true */ './bootstrap').then(({ default: bootstrap }) => bootstrap());
  } catch (error) {
    console.error(error);
  }
})();

import { isBefore, isValid } from 'date-fns';

export const MAX_PERSISTENT_RETRIES = 12;
export const LOCAL_STORAGE_MAX_RETRIES_REACHED = 'Local Storage Max Retries Reached';

const removeFirstToExpireItem = (excludedKey) => {
  const keys = Object.keys(window.localStorage);

  let firstToExpireDate: Date = null;
  let firstToExpireKey = '';

  if (keys) {
    keys.forEach((key) => {
      if (key === excludedKey) {
        return;
      }

      const value = window.localStorage[key];
      if (value) {
        try {
          const obj = JSON.parse(value);
          if (!obj.expires) {
            return;
          }

          const expires = new Date(obj.expires);
          if (isValid(expires) && (firstToExpireDate === null || isBefore(expires, firstToExpireDate))) {
            firstToExpireDate = expires;
            firstToExpireKey = key;
          }
        } catch (ex) {}
      }
    });

    if (firstToExpireKey !== '') {
      window.localStorage.removeItem(firstToExpireKey);
    }
  }
};

export const setLocalStorageItemPersistent = (key: string, value: string): void => {
  let retriesCount = 0;

  while (true) {
    try {
      window.localStorage.setItem(key, value);
      break;
    } catch (e) {
      if (retriesCount++ === MAX_PERSISTENT_RETRIES) {
        console.log(LOCAL_STORAGE_MAX_RETRIES_REACHED, e);
        break;
      }

      removeFirstToExpireItem(key);
      continue;
    }
  }
};

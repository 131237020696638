import { Observable, Subject } from 'rxjs';
import { AbstractControl, ValidatorFn } from '@angular/forms';

declare global {
  interface Window {
    canvasMeasure: HTMLCanvasElement;
  }
}

export const inIFrame = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const appendScript = (
  url: string,
  isAsync = false,
  attributes?: { [key: string]: string },
): Observable<boolean> => {
  const result = new Subject<boolean>();

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  script.async = isAsync;

  if (attributes) {
    for (const [key, value] of Object.entries(attributes)) {
      script.setAttribute(key, value);
    }
  }

  script.onload = () => {
    result.next(true);
    result.complete();
  };

  script.onerror = () => {
    result.error('Error loading script');
  };

  document.getElementsByTagName('head')[0].appendChild(script);

  return result.asObservable();
};

export const appendStyles = (styles: string): Observable<boolean> => {
  const result = new Subject<boolean>();

  const style = document.createElement('style');
  style.append(styles);

  style.onload = () => {
    result.next(true);
    result.complete();
  };

  style.onerror = () => {
    result.error('Error loading styles');
  };

  document.getElementsByTagName('head')[0].appendChild(style);

  return result.asObservable();
};

export const deleteElementByID = (scriptID: string): void => {
  const elem = document.getElementById(scriptID);
  if (elem) {
    elem.parentNode.removeChild(elem);
  }
};

export const passwordPattern = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }

    const regex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$');
    return regex.test(control.value) ? null : { pattern: true };
  };
};

export const base64ToBlob = (dataURI: string): Blob => {
  let byteString;
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  const ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const cssVar = (varName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(varName);
};

/**
 * Determine if an element of any or its children have a CSS animation applied
 * @param {HTMLElement} el - element to inspect
 * @returns {boolean} true if an animation/transition detected, otherwise false
 */
export const hasCssAnimation = (el: HTMLElement) => {
  // get the applied styles
  const style = window.getComputedStyle(el, null);
  // read the animation/transition duration - defaults to 0
  const animDuration = parseFloat(style.getPropertyValue('animation-duration') || '0');
  const transDuration = parseFloat(style.getPropertyValue('transition-duration') || '0');
  // if we have any duration greater than 0, an animation exists
  if (animDuration > 0 || transDuration > 0) {
    return true;
  }

  return false;
};

export const getTextWidth = (text: string, font: string) => {
  const canvas = window.canvasMeasure || (window.canvasMeasure = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  return Math.ceil(context.measureText(text).width);
};

// returns full CDN url for the image
export const cdnImageUrl = (relativeUrl: string): string => {
  return `https://d2w7f1pl8j4yzn.cloudfront.net/${relativeUrl}`;
};

export const cdnImageUrlCloud = (relativeUrl: string): string => {
  return `https://res.cloudinary.com/tripactions/image/upload/${relativeUrl}`;
};

export const localizePlural = (value, options) => {
  // Handle 0, 1, ... cases
  const directResult = options[value];
  if (directResult !== undefined) {
    return directResult;
  }
  // handle zero, one, two, few, many
  // ...
  return options.other;
};

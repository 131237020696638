import * as Bowser from 'bowser';
import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE } from '../models/ta-locale.model';

export const browserUtils = {
  isIosWebView: () => {
    return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(navigator?.userAgent);
  },
  isIos: () => {
    const browserInfo = Bowser.parse(window.navigator.userAgent);
    return browserInfo?.os?.name === 'iOS';
  },
  isAndroid: () => {
    const browserInfo = Bowser.parse(window.navigator.userAgent);
    return browserInfo?.os?.name === 'Android';
  },
  encodeUrlPart: (part: string) => {
    const blob = window.btoa(part);
    return encodeURIComponent(blob);
  },
  decodeUrlPart: (part: string) => {
    const blob = decodeURIComponent(part);
    return window.atob(blob);
  },

  getBrowserLocale: (): string => {
    for (const lang of navigator.languages) {
      const shortLang = lang.substr(0, 2);
      const exactLocale = AVAILABLE_LANGUAGES.find((avLang) => avLang.code === lang);

      if (exactLocale) {
        return exactLocale.code;
      } else {
        const shortLocale = AVAILABLE_LANGUAGES.find((avLang) => avLang.code.includes(shortLang));

        if (shortLocale) {
          return shortLocale.code;
        }
      }
    }

    return DEFAULT_LOCALE;
  },

  getCookie: (name: string) => {
    return document.cookie.split(';').reduce((res, c) => {
      const [key, ...val] = c.trim().split('=').map(decodeURIComponent);
      return Object.assign(res, { [key]: val.join('=') });
    }, {})[name];
  },
};

import { isBefore } from 'date-fns';
import { setLocalStorageItemPersistent } from '@tripactions/core/util/storage.util';

export interface TxLoginAsSession {
  backUrl: string;
  copyOnCheckoutEnabled?: boolean;
  email: string;
  expires: string;
  loginAsToken: string;
  originalToken: string;
  startUrl: string;
}

interface SessionImpersonate {
  newToken: string;
  originalToken: string;
  url: string;
}

interface RegularLoginAsSession {
  expires: Date;
  impersonate: SessionImpersonate;
}

export const LOGIN_AS_SESSIONS_KEY = 'Navan.LoginAsSessions';
export const CURRENT_LOGIN_AS_EMAIL_KEY = 'Navan.CurrentLoginAsEmail';
export const LAST_LOGIN_AS_EMAIL_KEY = 'Navan.LastLoginAsEmail';

export const getTxLoginAsSessions = (): TxLoginAsSession[] => {
  try {
    return JSON.parse(localStorage.getItem(LOGIN_AS_SESSIONS_KEY)) || [];
  } catch (e) {
    return [];
  }
};

export const addNewTxLoginAsSession = (newSession: TxLoginAsSession) => {
  const currentSessions = getTxLoginAsSessions();
  const activeSessions = currentSessions?.filter((session) => isBefore(new Date(), new Date(session.expires)));
  const existingSession = activeSessions?.find((session) => session.email === newSession?.email);

  setLocalStorageItemPersistent(
    LOGIN_AS_SESSIONS_KEY,
    JSON.stringify(existingSession ? activeSessions : [...activeSessions, newSession]),
  );
};

// Current session is stored per tab and used to update last used session email
// Will be 'null' in case if user logged in as him/herself
export const getCurrentTxLoginAsEmail = (): string => sessionStorage.getItem(CURRENT_LOGIN_AS_EMAIL_KEY);
export const setCurrentTxLoginAsEmail = (email: string | null) =>
  sessionStorage.setItem(CURRENT_LOGIN_AS_EMAIL_KEY, email);

// Used to set the same session as last one in a new tab
// Will be 'null' in case if user was last logged in as him/herself
export const getLastTxLoginAsEmail = (): string => localStorage.getItem(LAST_LOGIN_AS_EMAIL_KEY);
export const setLastTxLoginAsEmail = (email: string | null) =>
  setLocalStorageItemPersistent(LAST_LOGIN_AS_EMAIL_KEY, email);

export const getCurrentTxLoginAsSession = (): TxLoginAsSession | null =>
  getTxLoginAsSessions().find((s) =>
    getCurrentTxLoginAsEmail() ? s.email === getCurrentTxLoginAsEmail() : s.email === getLastTxLoginAsEmail(),
  ) || null;

export const getTxLoginAsSessionToken = (): null | string => getCurrentTxLoginAsSession()?.loginAsToken || null;

export const removeCurrentTxLoginAsSession = (): void => {
  setLocalStorageItemPersistent(
    LOGIN_AS_SESSIONS_KEY,
    JSON.stringify(getTxLoginAsSessions()?.filter((session) => session.email !== getCurrentTxLoginAsEmail())),
  );

  setCurrentTxLoginAsEmail(null);
  setLastTxLoginAsEmail(null);
};

export const getRegularLoginAsSession = (): RegularLoginAsSession | null => {
  let session: RegularLoginAsSession;

  try {
    session = JSON.parse(localStorage.getItem('tripactions.TripActionsSession'));
  } catch (err) {
    session = null;
  }

  return session;
};

export const cleanupTxLoginAs = () => {
  setLocalStorageItemPersistent(LOGIN_AS_SESSIONS_KEY, JSON.stringify([]));
  localStorage.removeItem(LAST_LOGIN_AS_EMAIL_KEY);
  sessionStorage.removeItem(CURRENT_LOGIN_AS_EMAIL_KEY);
};

export const cleanupRegularLoginAs = () => {
  const regularLoginAsSession = getRegularLoginAsSession();

  localStorage.removeItem('tripactions.TripActionsSession');
  setLocalStorageItemPersistent(
    'tripactions.TripActionsToken',
    regularLoginAsSession?.impersonate?.originalToken || localStorage.getItem('tripactions.TripActionsToken') || null,
  );
};

import { JwtData } from '../models';
import { inIFrame } from './general.util';
import { getTxLoginAsSessionToken, getRegularLoginAsSession } from './login-as.util';

// todo: proper import this method
function monitorError(owningTeam: string, feature: string, source: string, error: Error): void {
  // @ts-ignore
  if (typeof window.newrelic === 'object' && window.newrelic.noticeError) {
    // @ts-ignore
    window.newrelic.noticeError(
      new Error(`[owningTeam: ${owningTeam}, feature: ${feature}, source: ${source}] ${error}`),
    );
  }
}

// note: auth0 signed tokens will have custom claims namespaced
const Auth0TokenNameSpace = 'https://tripactions.com/';
const stripJwtTokenNameSpace = (payload: unknown): JwtData => {
  const nameSpaceRegEx = new RegExp(`^${Auth0TokenNameSpace}`);

  return Object.keys(payload as Record<string, string | string[]>).reduce(
    (modifiedPayload: Partial<JwtData>, k: string): Partial<JwtData> => {
      if (nameSpaceRegEx.test(k)) {
        const newKey = k.replace(nameSpaceRegEx, '');
        modifiedPayload[newKey] = payload[k];
      } else {
        if (!modifiedPayload[k]) {
          modifiedPayload[k] = payload[k];
        }
      }

      return modifiedPayload;
    },
    {},
  ) as JwtData;
};

// protect against invalid json characters (anything below 32)
const cleanupString = (str: string): string => {
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    if (char < 32) {
      str = str.substring(0, i) + str.substring(i + 1);
      i--;
    }
  }
  return str;
};

const urlBase64Decode = (str: string): string => {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      output += '==';
      break;
    }
    case 3: {
      output += '=';
      break;
    }
    default: {
      throw new Error('Illegal base64url string!');
    }
  }
  return decodeURIComponent(encodeURIComponent(window.atob(output)));
};

export const getLocalStorageToken = (): string => {
  let token = localStorage.getItem('tripactions.TripActionsToken');

  if (token === 'null') {
    token = null;
  }

  token = token ? token.replace(/"/g, '') : token;

  return token && token !== 'undefined' ? token : null;
};

export const getAgentToken = (): string | null => {
  try {
    const token = getLocalStorageToken();
    const session = getRegularLoginAsSession();

    return session?.impersonate?.originalToken || token || null;
  } catch (err) {
    return null;
  }
};

export const getAgentEmail = (): string | null => {
  try {
    const token = getLocalStorageToken();

    if (!token) {
      return null;
    }

    const jwt = jwtUtils.parseToken(token);
    const session = getRegularLoginAsSession();

    if (jwt?.impersonated && session?.impersonate?.originalToken) {
      const { email } = jwtUtils.parseToken(session?.impersonate?.originalToken);

      return email;
    }

    return jwt.email;
  } catch (err) {
    return null;
  }
};

export const jwtUtils = {
  // reads and parses token. Returns jwtData
  getToken: (impersonatedUserEmail: string = null): string => {
    if (inIFrame()) {
      const sessionItem = 'tripactions.TripActionsSession';

      let session;

      if (impersonatedUserEmail) {
        session = window.sessionStorage.getItem(`${sessionItem}:${impersonatedUserEmail}`);
      } else {
        session = window.sessionStorage.getItem(sessionItem);
      }

      const parsedSession = JSON.parse(session);

      if (parsedSession?.impersonate) {
        return parsedSession.impersonate.newToken;
      }
    }

    const loginAsToken = getTxLoginAsSessionToken();

    if (loginAsToken) {
      return loginAsToken;
    }

    return getLocalStorageToken();
  },

  // convert token string into jwtData object
  parseToken: (token: string): JwtData => {
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    const decoded = urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    let jwtData: JwtData;

    try {
      jwtData = stripJwtTokenNameSpace(JSON.parse(cleanupString(decoded))) as JwtData;
    } catch (error) {
      monitorError('Core', 'JWT', 'parseToken', error);
      jwtData = JSON.parse(cleanupString(decoded)) as JwtData;
    }

    jwtData.uuid = jwtData['sub'];
    jwtData.familyName = jwtData['family_name'];
    jwtData.givenName = jwtData['given_name'];
    return jwtData;
  },
};

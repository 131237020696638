import { registerLocaleData } from '@angular/common';
import { loadTranslations } from '@angular/localize';
import { jwtUtils } from './jwt.util';
import { browserUtils } from './browser.util';
import { SUDO_STORAGE, ONBOARDING_LOCALE_STORAGE } from '../models/ta-locale.model';

export const DEFAULT_ANGULAR_LOCALE = 'en';

export const MESSAGES_MAP = {
  sudo: './assets/i18n/messages.custom-1.json',
  de: './assets/i18n/messages.de.json',
  fr: './assets/i18n/messages.fr.json',
  es: './assets/i18n/messages.es.json',
  nl: './assets/i18n/messages.nl.json',
  it: './assets/i18n/messages.it.json',
  pl: './assets/i18n/messages.pl-pl.json',
  pt: './assets/i18n/messages.pt-br.json',
  'pt-PT': './assets/i18n/messages.pt-pt.json',
  sv: './assets/i18n/messages.sv.json',
};

export function extractBaseLocale(localeId: string): string | undefined {
  return localeId?.split('-')?.[0];
}

export function importLocaleFileUnsafe(localeId: string): Promise<any> {
  return import(
    /* webpackInclude: /(en|en-GB|de|fr|es|nl|it|pt|sv|pl)\.mjs/ */
    /* webpackMode: "lazy-once" */
    /* webpackChunkName: "i18n-base" */
    /* webpackPreload */
    `node_modules/@angular/common/locales/${localeId}.mjs`
  );
}

export function importLocaleFile(localeId: string): Promise<any> {
  return (
    importLocaleFileUnsafe(localeId)
      // Fallback to a base locale eg. pt-BR -> pt
      .catch(() => importLocaleFileUnsafe(extractBaseLocale(localeId)))
      // Fallback to the default locale
      .catch(() => importLocaleFileUnsafe(DEFAULT_ANGULAR_LOCALE))
  );
}

export class TaBootstrapData {
  origLocaleId: string;
  localeId: string;

  constructor() {
    const userLocaleId = this.getUserLocale();
    this.setLocaleId(userLocaleId);
  }

  setLocaleId(localeId: string) {
    this.localeId = localeId;
    if (!this.localeId) {
      // get locale from browser
      this.localeId = browserUtils.getBrowserLocale();
    }
    this.origLocaleId = this.localeId;
  }

  async setup(): Promise<any> {
    return Promise.all([
      await this.loadTranslations(this.localeId),
      // lazily loading locale from the file and when ready bootstrap main app
      await importLocaleFile(this.localeId).then(({ default: locale }) => {
        registerLocaleData(locale, this.localeId);
      }),
    ]);
  }

  private async loadTranslations(localeId: string): Promise<void> {
    let languageUrl = this.getLanguageUrl(localeId);

    if (languageUrl) {
      const messages = await fetch(languageUrl).then((response) => response.json());
      loadTranslations(messages);
    }
  }

  private getUserLocale() {
    // check what locale is set for logged user
    const token = jwtUtils.getToken();
    const jwtData = token && jwtUtils.parseToken(token);
    const userUuid = jwtData && jwtData.uuid;
    const onboardingLocale = localStorage.getItem(ONBOARDING_LOCALE_STORAGE);

    if (onboardingLocale) {
      return onboardingLocale;
    }

    if (userUuid) {
      // user is logged in. Get locale from userCache
      const userCacheJson = localStorage.getItem(`tripactions.user-${userUuid}`);
      const userCache = userCacheJson ? JSON.parse(userCacheJson) : {};
      return userCache.locale ? userCache.locale : browserUtils.getBrowserLocale();
    }

    return browserUtils.getBrowserLocale();
  }

  private getLanguageUrl(localeId: string): string | null {
    if (window.localStorage.getItem(SUDO_STORAGE) === 'true') {
      return MESSAGES_MAP.sudo;
    }

    if (MESSAGES_MAP[localeId]) {
      return MESSAGES_MAP[localeId];
    }

    localeId = extractBaseLocale(localeId) ?? DEFAULT_ANGULAR_LOCALE;
    if (MESSAGES_MAP[localeId]) {
      return MESSAGES_MAP[localeId];
    }

    return null;
  }
}

export const TA_BOOTSTRAP = new TaBootstrapData();

export const SUDO_NAME = $localize`Sudo`;
export const SUDO_CODE = 'sudo';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_LOCALE_OBJECT = {
  name: $localize`English (United States)`,
  code: 'en-US',
};
export const AVAILABLE_LANGUAGES = [
  DEFAULT_LOCALE_OBJECT,
  {
    name: $localize`English (United Kingdom)`,
    code: 'en-GB',
  },
  {
    name: `German (Deutsch)`,
    code: 'de-DE',
  },
  {
    name: `French (Français)`,
    code: 'fr-FR',
  },
  {
    name: `Spanish (Español)`,
    code: 'es-ES',
  },
  {
    name: `Dutch (Nederlands)`,
    code: 'nl-NL',
  },
  {
    name: `Italian (Italiano)`,
    code: 'it-IT',
  },
  {
    name: `Portuguese - Portugal (Português - Portugal)`,
    code: 'pt-PT',
  },
  {
    name: `Portuguese - Brazilian (Português-Brazil)`,
    code: 'pt-BR',
  },
  {
    name: `Swedish (Svenska)`,
    code: 'sv-SE',
  },
  {
    name: `Polish (Polski)`,
    code: 'pl-PL',
  },
];

export const SUDO_STORAGE = 'tripactions.sudo-language';
export const ONBOARDING_LOCALE_STORAGE = 'tripactions.onboarding-locale';
